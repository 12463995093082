import React from "react";
import styled from "styled-components";
import LineArrowRight from "../../Icons/LineArrowRight";
import { DropWrap, DropItem, Divider } from "./StorageBuildings"

const Wrap = styled(DropWrap)`
	max-height: 400px;
	overflow-y: scroll;
	left: auto;
	right: 0;
	display: ${props => props.display};

	:before {
		left: 90%;
	}
	@media(max-width: 1200px) {
		position: relative;
		width: 100%;
		right: 0;
		:before {
			display: none;
		}
	}
`

const Resources = ({ display }) => {
	return(
		<Wrap display={display}>
			<DropItem to="/about-us">
				About Us <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/easy-payment-options">
				Easy Payment Options <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/financing">
				Financing <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/rto">
				Rent To Own <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/help-and-info">
				Help and Info <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/service-area">
				Service Area <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/faq">
				FAQ's <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/gallery">
				Gallery <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<Divider />
			<DropItem to="/blog">
				Blogs <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/reviews">
				Reviews <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/contact">
				Contact Us <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
		</Wrap>
	)
};

export default Resources;