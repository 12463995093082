
import React from 'react'

const NoteIcon = (props) => {
  return(
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" viewBox="0 0 22 26">
    <g id="call2" transform="translate(-1.006)">
      <g id="notes" transform="translate(1.006)">
        <path id="Path_6" data-name="Path 6" d="M6.908,5.958A1.123,1.123,0,0,1,5.75,4.875V1.083A1.123,1.123,0,0,1,6.908,0,1.123,1.123,0,0,1,8.066,1.083V4.875A1.123,1.123,0,0,1,6.908,5.958Z" transform="translate(-1.408)" fill="#fff"/>
        <path id="Path_7" data-name="Path 7" d="M11.658,5.958A1.123,1.123,0,0,1,10.5,4.875V1.083a1.16,1.16,0,0,1,2.316,0V4.875A1.123,1.123,0,0,1,11.658,5.958Z" transform="translate(-0.658)" fill="#fff"/>
        <path id="Path_8" data-name="Path 8" d="M16.408,5.958A1.123,1.123,0,0,1,15.25,4.875V1.083a1.16,1.16,0,0,1,2.316,0V4.875A1.123,1.123,0,0,1,16.408,5.958Z" transform="translate(0.092)" fill="#fff"/>
        <path id="Path_9" data-name="Path 9" d="M20.816,2.25H5.184A3.089,3.089,0,0,0,2,5.229v17.6a3.089,3.089,0,0,0,3.184,2.979H20.816A3.089,3.089,0,0,0,24,22.833V5.229A3.089,3.089,0,0,0,20.816,2.25ZM7.789,8.479h5.789a1.086,1.086,0,1,1,0,2.167H7.789a1.086,1.086,0,1,1,0-2.167ZM19.368,19.312H7.789a1.086,1.086,0,1,1,0-2.167H19.368a1.086,1.086,0,1,1,0,2.167Zm0-4.333H7.789a1.086,1.086,0,1,1,0-2.167H19.368a1.086,1.086,0,1,1,0,2.167Z" transform="translate(-2 0.187)" fill="#fff"/>
      </g>
      <g id="notes-2" data-name="notes" transform="translate(1.006)">
        <path id="Path_6-2" data-name="Path 6" d="M6.908,5.958A1.123,1.123,0,0,1,5.75,4.875V1.083A1.123,1.123,0,0,1,6.908,0,1.123,1.123,0,0,1,8.066,1.083V4.875A1.123,1.123,0,0,1,6.908,5.958Z" transform="translate(-1.408)" fill="#fff"/>
        <path id="Path_7-2" data-name="Path 7" d="M11.658,5.958A1.123,1.123,0,0,1,10.5,4.875V1.083a1.16,1.16,0,0,1,2.316,0V4.875A1.123,1.123,0,0,1,11.658,5.958Z" transform="translate(-0.658)" fill="#fff"/>
        <path id="Path_8-2" data-name="Path 8" d="M16.408,5.958A1.123,1.123,0,0,1,15.25,4.875V1.083a1.16,1.16,0,0,1,2.316,0V4.875A1.123,1.123,0,0,1,16.408,5.958Z" transform="translate(0.092)" fill="#fff"/>
        <path id="Path_9-2" data-name="Path 9" d="M20.816,2.25H5.184A3.089,3.089,0,0,0,2,5.229v17.6a3.089,3.089,0,0,0,3.184,2.979H20.816A3.089,3.089,0,0,0,24,22.833V5.229A3.089,3.089,0,0,0,20.816,2.25ZM7.789,8.479h5.789a1.086,1.086,0,1,1,0,2.167H7.789a1.086,1.086,0,1,1,0-2.167ZM19.368,19.312H7.789a1.086,1.086,0,1,1,0-2.167H19.368a1.086,1.086,0,1,1,0,2.167Zm0-4.333H7.789a1.086,1.086,0,1,1,0-2.167H19.368a1.086,1.086,0,1,1,0,2.167Z" transform="translate(-2 0.187)" fill="#fff"/>
      </g>
    </g>
  </svg>
  )
}

export default NoteIcon
