import React from 'react'

const DropArrow = (props) => {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="8.828" height="5.828" viewBox="0 0 8.828 5.828">
		  <path id="Path_18986" data-name="Path 18986" d="M1168,14l-3,3-3-3" transform="translate(-1160.586 -12.586)" fill="none" stroke={props.fill} stroke-linecap="round" stroke-width="2"/>
		</svg>

	)
}

export default DropArrow