/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ description, lang, meta, title, schemaMarkup }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const commonSchema = { 
    "@context": "https://schema.org", 
    "@type": "LocalBusiness", 
    "name": "Buildings And More", 
    "image": "https://www.buildingsandmore.com/static/15071556b56eb7f9570713e6b266eebb/8abb9/logo.webp", 
    "@id": "", 
    "url": "https://www.buildingsandmore.com/", 
    "telephone": "+1 (386) 755-6449", 
    "address": { 
      "@type": "PostalAddress", 
      "streetAddress": "792 SW Bascom Norris Drive", 
      "addressLocality": "Lake City", 
      "addressRegion": "FL", 
      "postalCode": "32025", 
      "addressCountry": "US" 
    }, 
    "geo": { 
      "@type": "GeoCoordinates", 
      "latitude": 30.1720092, 
      "longitude": -82.6699471 
    }, 
    "openingHoursSpecification": [{ 
      "@type": "OpeningHoursSpecification", 
      "dayOfWeek": [ 
        "Monday", 
        "Tuesday", 
        "Wednesday", 
        "Thursday", 
        "Friday" 
      ], 
      "opens": "09:00", 
      "closes": "17:00" 
    },{ 
      "@type": "OpeningHoursSpecification", 
      "dayOfWeek": "Saturday", 
      "opens": "09:00", 
      "closes": "14:00" 
    }],
    "sameAs": [
      "https://www.facebook.com/BuildingsAndMore/", 
      "https://www.instagram.com/buildingsandmorefl/", 
      "https://www.youtube.com/channel/UC4gVU1Ei9nbWT6yNmYllSQQ", 
      "https://www.pinterest.com/buildingsandmore/", 
      "https://www.buildingsandmore.com/" 
    ] 
  }
  return (
    <>
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://www.buildingsandmore.com/static/15071556b56eb7f9570713e6b266eebb/df682/logo.png`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `https://www.buildingsandmore.com/static/15071556b56eb7f9570713e6b266eebb/df682/logo.png`,
        },
        {
          name: `og:url`,
          content: `https://www.buildingsandmore.com/`,
        },
      ].concat(meta)}
    />
    {schemaMarkup ? (
      <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
    ):(
      <script type="application/ld+json">{JSON.stringify(commonSchema)}</script>
    )}
    </>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
