import React from 'react'

const Youtube = (props) => {
  return(
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25" viewBox="0 0 32 25">
    <g id="youtube" transform="translate(-1281 -10779.002)">
      <path id="youtube-2" data-name="youtube" d="M26,25H6a6.007,6.007,0,0,1-6-6V6A6.007,6.007,0,0,1,6,0H26a6.007,6.007,0,0,1,6,6V19A6.007,6.007,0,0,1,26,25ZM12.846,6.168a1,1,0,0,0-1,1V17.335a1,1,0,0,0,1,1,.985.985,0,0,0,.493-.133l8.921-5.083a1,1,0,0,0,0-1.737L13.339,6.3A.985.985,0,0,0,12.846,6.168Z" transform="translate(1281 10779.002)" fill="#fff"/>
    </g>
  </svg>

  )
}

export default Youtube
