import React from "react";
import LineArrowRight from "../../Icons/LineArrowRight";
import { DropWrap, DropItem, AllBuildings, Divider } from "./StorageBuildings"

const PortableBuildings = ({ display }) => {
	return(
		<DropWrap display={display}>
			<DropItem to="/portable-buildings/end-gables">
        End Gables <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/portable-buildings/side-gables">
        Side Gables <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/portable-buildings/porch-models">
        Porch Models <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/portable-buildings/lofted-barns">
        Lofted Barns <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<Divider />
			<AllBuildings to="/portable-buildings">
        All Portable Buildings  <LineArrowRight fill="#E43D3C" />
			</AllBuildings>
		</DropWrap>
	)
};

export default PortableBuildings;