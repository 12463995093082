import React from 'react'

const Facebook = (props) => {
  return(
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="30" viewBox="0 0 14 30">
    <g id="facebook" transform="translate(-1299 -10777)">
      <g id="facebook-2" data-name="facebook" transform="translate(1299 10777)">
        <path id="facebook-3" data-name="facebook" d="M17.631,4.981h2.556V.211A30.828,30.828,0,0,0,16.463,0c-3.685,0-6.21,2.484-6.21,7.049v4.2H6.187v5.333h4.067V30H15.24V16.584h3.9l.619-5.333H15.238V7.578c0-1.541.388-2.6,2.393-2.6Z" transform="translate(-6.187 0)" fill={props.fill}/>
      </g>
    </g>
  </svg>
  )
}

export default Facebook


