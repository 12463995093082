import React from "react";
import styled from "styled-components";
import { Link } from 'gatsby';

import LineArrowRight from "../../Icons/LineArrowRight";


export const DropWrap = styled.div`
	position: absolute;
	z-index: 10;
	display: ${props => props.display};
	top: ${props => props.top};
	left: ${props => props.left};
	width: 300px;
	padding: 20px 0 40px;
	background: #000000;
	box-shadow: 0 12px 25px rgba(0, 0, 0, .16);
	border-radius: 6px;

	&:before {
		content: "";
    position: absolute;
    z-index: 11;
    width: 0px;
    height: 0px;
    top: 1px;
    left: 15%;
    box-sizing: border-box;
    border: 0.5em solid #000000;
    transform-origin: 0px 0px;
    transform: rotate(135deg);
    box-shadow: rgb(0 0 0 / 10%) -3px 3px 4px -2px;
	}
	@media(max-width: 1200px) {
		position: relative;
		top: 5px;
		display: ${props => props.display};
		width: 100%;
		border-radius: 0;
		&:before {
			display: none;
		}
	}
`

DropWrap.defaultProps = {
	top: '30px',
	left: '0px',
}

export const DropItem = styled(Link)`
	padding: 18px 36px 18px 30px;
	border-left: 2px solid #E43D3C;
	color: white;
	display: block;
	font-size: 16px;

	background: linear-gradient(to left, #000000 50%, #E43D3C 50%) right;
    background-size: 200%;
    transition: .5s ease-out;

	svg {
		width: 12px;
		float: right;
	}

	:hover {
		background-position: left bottom;

		svg {
			transform: translateX(5px);
			
		}
	}
	@media(max-width: 1200px) {
		padding: 10px 45px;
	}
`

export const AllBuildings = styled(Link)`
	padding: 18px 36px 0px 30px;
	color: #E43D3C;
	display: block;
	font-size: 16px;

	svg {
		width: 12px;
		float: right;
	}

	:hover {
		opacity: 0.7;
	}
	@media(max-width: 1200px) {
		padding: 10px 45px;
	}
`

export const Divider = styled.div`
	width: 240px;
	margin: 0 auto;
	height: 2px;
	border-top: 2px solid #FFFFFF;

	@media(max-width: 1200px) {
		width: calc(100% - 66px);
		margin-left: 36px;
	}
`

const StorageBuildingsDropdown = ({ display }) => {
	return(
		<DropWrap display={display}>
			<DropItem to="/storage-buildings/portable-storage-sheds">
				Portable Storage Sheds <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/storage-buildings/utility-buildings">
				Utility Buildings <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<AllBuildings to="/storage-buildings">
				All Storage Buildings <LineArrowRight fill="#E43D3C" />
			</AllBuildings>
		</DropWrap>
	)
};

export default StorageBuildingsDropdown;