import React from "react";

const SearchCloseIcon = (props) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="9.069" height="9.072" viewBox="0 0 9.069 9.072">
            <path d="M8908.777,19114.2l-2.827-2.83-2.828,2.83a1,1,0,0,1-1.416-1.416l2.83-2.828-2.83-2.832a1,1,0,0,1,1.416-1.41l2.828,2.826,2.827-2.826a1,1,0,0,1,1.415,1.414l-2.826,2.828,2.826,2.824a1.01,1.01,0,0,1,0,1.42,1,1,0,0,1-.705.291A1.011,1.011,0,0,1,8908.777,19114.2Z" transform="translate(-8901.415 -19105.414)"/>
        </svg>
    )
}

export default SearchCloseIcon;