import { useEffect } from "react";

const useClickOutside = (refs, handler) => {
  useEffect(() => {
    const listener = (event) => {
      for (const ref of refs) {
        const el = ref?.current;

        // Do nothing if clicking ref's element or descendent elements
        if (!el || el.contains((event?.target) || null)) {
          return;
        }
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler]);
};

export default useClickOutside;
