import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryLinkBtn = styled(BaseButton)`
    padding: 9px 16px 8px;
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    background: #D51333;
    color:#fff;
    border-radius: 6px;
    box-shadow: 0 6px 0 #982221;
    &:after {       
        background: #A20000;
    }
	& .icon{
		> svg{
			stroke:#ffffff;
		}
	}
	&:hover{
        cursor: pointer;
	}
`

const PrimaryLinkButton = (props) => {
	const { preIcon, icon, text, size } = props;
	return(
		<PrimaryLinkBtn className={`btn btn-primary ${size ? `btn-${size}` : ''} `}>
            <>
                { preIcon &&
                    <span className='icon'>{preIcon}</span>
                }
                { text &&
                    <span className='text'>{text}</span>
                }
                { icon &&
                    <span className='icon'>{icon}</span>
                }
            </>

		</PrimaryLinkBtn>
	)
}

export default PrimaryLinkButton