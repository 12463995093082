import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const SecondBtn = styled(BaseButton)`
	background: linear-gradient(to top, #574C4C, #1F1D1D);
    color:#ffffff;
    padding: 9px 16px 8px;
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    font-size: 16px;
    border-radius: 6px;
    box-shadow: 0 6px 0 #000000;
    border: 0px;

    &:after {       
        background: #574C4C;
    }
	& .icon{
		> svg{
			stroke:#ffffff;
		}
	}
	&:hover{
        cursor: pointer;
	}
`

const SecondButton = (props) => {
	const { icon, text, size, preIcon } = props;
	return(
		<SecondBtn className={`btn btn-second ${size ? `btn-${size}` : ''} `}>
            <>
                { preIcon &&
                    <span className='icon'>{preIcon}</span>
                }
                { text &&
                    <span className='text'>{text}</span>
                }
                { icon &&
                    <span className='icon'>{icon}</span>
                }
            </>

		</SecondBtn>
	)
}

export default SecondButton