import React from "react";
import LineArrowRight from "../../Icons/LineArrowRight";
import { DropWrap, DropItem, AllBuildings, Divider } from "./StorageBuildings"

const SteelBuildings = ({ display }) => {
	return(
		<DropWrap display={display}>
			<DropItem to="/steel-buildings/metal-carports">
				Carports <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/steel-buildings/metal-garages">
				Garages <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/steel-buildings/metal-barns">
				Barns <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/steel-buildings/rv-covers">
				RV Covers <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/steel-buildings/boat-carports">
				Boat Carports <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<DropItem to="/steel-buildings/utility-carports">
				Utility Carports <LineArrowRight fill="#ffffff" />
			</DropItem>
			<Divider />
			<Divider />
			<AllBuildings to="/steel-buildings">
				All Steel Buildings <LineArrowRight fill="#E43D3C" />
			</AllBuildings>
		</DropWrap>
	)
};

export default SteelBuildings;