import React from 'react'

const LineArrowRight = (props) => {
	return(
<svg xmlns="http://www.w3.org/2000/svg" width="18.315" height="18.683" viewBox="0 0 18.315 18.683">
  <g id="Group_8638" data-name="Group 8638" transform="translate(-1351 -37.961)">
    <path id="Path_43" data-name="Path 43" d="M2382,23h14.693" transform="translate(-1030 25)" fill="none" stroke={props.fill} stroke-linecap="round" stroke-width="2"/>
    <path id="Path_44" data-name="Path 44" d="M6.034-1.713l2.921,9.77L-.966,10.412" transform="translate(1354.886 45.5) rotate(-30)" fill="none" stroke={props.fill} stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
</svg>

	)
}

export default LineArrowRight