
import React from 'react'

const MailIcon = (props) => {
  return(
    <svg id="mail" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <path id="Path_18863" data-name="Path 18863" d="M8.75,17.612v3.139a.507.507,0,0,0,.916.3l1.836-2.5Z" transform="translate(-2.057 -3.259)" fill="#fff"/>
      <path id="Path_18864" data-name="Path 18864" d="M17.764.111a.534.534,0,0,0-.586-.043L.3,9.45a.609.609,0,0,0-.3.576.594.594,0,0,0,.379.521l4.691,1.707L15.062,3.16,7.331,13.077l7.862,2.861a.534.534,0,0,0,.474-.055.6.6,0,0,0,.264-.423L17.994.687a.618.618,0,0,0-.23-.576Z" transform="translate(0 0)" fill={props.fill}/>
    </svg>
  )
}

export default MailIcon