import React from 'react'

const PhoneIcon = (props) => {
	return(
		<svg id="call2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
		  <g id="Group_4" data-name="Group 4" transform="translate(0)">
		    <path id="Path_3" data-name="Path 3" d="M16.732,58.579a1.542,1.542,0,0,0-2.33,0c-.545.54-1.09,1.08-1.625,1.63a.321.321,0,0,1-.449.082c-.353-.192-.728-.348-1.067-.559a16.9,16.9,0,0,1-4.075-3.708,9.653,9.653,0,0,1-1.46-2.339.335.335,0,0,1,.082-.43c.545-.526,1.076-1.067,1.611-1.607a1.547,1.547,0,0,0,0-2.385c-.426-.43-.852-.852-1.277-1.282S5.264,47.1,4.82,46.662a1.551,1.551,0,0,0-2.33,0c-.549.54-1.076,1.094-1.634,1.625a2.653,2.653,0,0,0-.833,1.79,7.584,7.584,0,0,0,.586,3.264A19.834,19.834,0,0,0,4.128,59.21a21.786,21.786,0,0,0,7.215,5.645,10.435,10.435,0,0,0,4,1.163,2.929,2.929,0,0,0,2.513-.957c.467-.522.993-1,1.488-1.5a1.557,1.557,0,0,0,.009-2.371Q18.046,59.881,16.732,58.579Z" transform="translate(-0.006 -44.025)" fill={props.fill}/>
		    <path id="Path_4" data-name="Path 4" d="M242.817,102.51l1.689-.288A7.583,7.583,0,0,0,238.092,96l-.238,1.7a5.86,5.86,0,0,1,4.963,4.811Z" transform="translate(-226.965 -91.605)" fill={props.fill} />
		    <path id="Path_5" data-name="Path 5" d="M250.134,3.562A12.456,12.456,0,0,0,242.992,0l-.238,1.7a10.869,10.869,0,0,1,9.2,8.913l1.689-.288A12.547,12.547,0,0,0,250.134,3.562Z" transform="translate(-231.641)" fill={props.fill} />
		  </g>
		</svg>

	)
}

export default PhoneIcon