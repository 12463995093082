import React from 'react'
const ThreeDIcon = (props) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="24.665" height="20" viewBox="0 0 24.665 20">
      <path id="Union_33" data-name="Union 33" d="M17.777,19V6L24.5,6V18.45a.55.55,0,0,1-.55.55Zm-6.825-.007h-.02V12.639a.516.516,0,0,0-.516-.515h-4.4a.516.516,0,0,0-.515.515v6.354H.55A.551.551,0,0,1,0,18.442V6.341a.555.555,0,0,1,.21-.433l7.7-6.05a.548.548,0,0,1,.679,0l7.7,6.05a.553.553,0,0,1,.211.433v12.1a.551.551,0,0,1-.55.551ZM17.628,4.784,9.576-1h7.1a.554.554,0,0,1,.34.117l7.512,5.509c.015.013.029.025.043.039l.025.027.012.014c.01.012.02.025.029.038l0,0a.467.467,0,0,1,.028.045Z" transform="translate(0 1)" fill={props.fill}/>
    </svg>
  )
}

export default ThreeDIcon

